import TablePage from '@/found/components/table_page';

export default {
  extends: TablePage,
  props: {
    propsObj: Object,
  },
  data() {
    return {
      requestType: 'POST',
      params: { channelScanCodeRewardId: this.propsObj.id },
    };
  },
  created() {
    this.getConfigList('detail_list');
  },
  methods: {},
};
